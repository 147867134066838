import React, { useState, useEffect } from 'react';
import '../App.css';
import ModelsGalleryView from '../Components/Models Gallery View/modelsGalleryView';
import data from '../production.json'; // Ensure this path is correct

const PostProductions = ({ selectedCategory, selectedSubcategory }) => {
  const [filteredModels, setFilteredModels] = useState([]);

  useEffect(() => {
    // Filter models based on the selected category and subcategory
    const filtered = data.filter(model => {
      // Check if model's entryType matches the selectedCategory
      const matchesCategory = selectedCategory ? model.entryType.toLowerCase() === selectedCategory.toLowerCase() : true;
      // Check if model's category matches the selectedSubcategory
      const matchesSubcategory = selectedSubcategory ? model.category.toLowerCase() === selectedSubcategory.toLowerCase() : true;

      return matchesCategory && matchesSubcategory; // Model must match both conditions to be included
    });

    setFilteredModels(filtered);
  }, [selectedCategory, selectedSubcategory]);

  return (
    <div className="ProductionPage">
      <ModelsGalleryView models={filteredModels} /> {/* ModelsGalleryView receives the filtered model objects */}
    </div>
  );
};

export default PostProductions;
