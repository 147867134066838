import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import css from './header.module.css';

const Header = ({ setid }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMobileMenu = () => {
    setIsMenuOpen(true);
    document.querySelector(`#header_textContainer`).style.display = 'flex';
  };

  const handleCloseMenu = () => {
    if (isMobile) {
      setIsMenuOpen(false);
      document.querySelector(`#header_textContainer`).style.display = 'none';
    }
  };

  const activeLinkStyle = { color: '#EA2A2A' };

  return (
    <div className={css.headerParent}>
      <div className={css.Container}>
        <div className={css.logoContainer}>
          <NavLink
            to="/"
            onClick={() => {
              setid('');
              handleCloseMenu();
            }}
          >
            <img
              className="headerLogoImage"
              src="https://static.wixstatic.com/media/6fdf79_74261a9521f342819a13aed8e01dd3a5~mv2.jpg/v1/fill/w_271,h_160,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/MAGNETIC%20LOGO.jpg"
              alt="logo"
            />
          </NavLink>
          {isMobile && (
            <div className={css.linksUnderLogo}>
              <NavLink
                to="/talents"
                onClick={() => setid('')}
                style={location.pathname === '/talents' ? activeLinkStyle : null}
              >
                Talents
              </NavLink>
              <NavLink
                to="/pre-production"
                onClick={() => setid('')}
                style={location.pathname === '/pre-production' ? activeLinkStyle : null}
              >
                Pre Production
              </NavLink>
              <NavLink
                to="/post-production"
                onClick={() => setid('')}
                style={location.pathname === '/post-production' ? activeLinkStyle : null}
              >
                Post Production
              </NavLink>
            </div>
          )}
        </div>
        {!isMenuOpen && isMobile && (
          <div className={css.hamburger} onClick={handleMobileMenu}>
            <img src="/black_hamburger.svg" alt="hamburger" />
          </div>
        )}
        <div className={css.HeaderTextContainer} id="header_textContainer">
          {isMenuOpen && isMobile && (
            <div className={css.crossButton} onClick={handleCloseMenu}>
              X
            </div>
          )}
            <NavLink to="/talents" onClick={() => { setid(''); handleCloseMenu(); }} style={location.pathname === '/talents' ? activeLinkStyle : null} className={css.desktopOnlyLink}>
            Talents
          </NavLink>
          <NavLink to="/pre-production" onClick={() => { setid(''); handleCloseMenu(); }} style={location.pathname === '/pre-production' ? activeLinkStyle : null} className={css.desktopOnlyLink}>
            Pre Production
          </NavLink>
          <NavLink to="/post-production" onClick={() => { setid(''); handleCloseMenu(); }} style={location.pathname === '/post-production' ? activeLinkStyle : null} className={css.desktopOnlyLink}>
            Post Production
          </NavLink>
          <NavLink to="/about-us" onClick={() => { setid(''); handleCloseMenu(); }} style={location.pathname === '/about-us' ? activeLinkStyle : null}>
            About Us
          </NavLink>
          <NavLink to="/join-us" onClick={() => { setid(''); handleCloseMenu(); }} style={location.pathname === '/join-us' ? activeLinkStyle : null}>
            Join Us
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Header;
