import React, { useState } from 'react';
import './JoinUs.scss';
import axios from 'axios';

const JoinUsForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    instaID: '',
    email: '',
    contact: '',
    message: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { firstName, instaID, email, contact, message } = formData;

    try {
      await axios.post('https://admin.wlbs.dev/api/public-api/send-email', {
        to: email, // Assuming email is the recipient's email address
        subject: 'Join Us Form Submission',
        text: `First Name: ${firstName}\nInsta ID: ${instaID}\nEmail: ${email}\nContact: ${contact}\nMessage: ${message}`,
      });

      setFormData({
        firstName: '',
        instaID: '',
        email: '',
        contact: '',
        message: '',
      });

      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email. Please try again later.');
    }
  };

  return (
    <div className="joinUsFormContainerMain">
      <div className="joinUsFormContainer">
        <form className="joinUsForm" onSubmit={handleSubmit}>
          {/* Form fields */}
          {/* First Name */}
          <div className="joinUsFormGroup joinUsFirstNameGroup">
            <label htmlFor="firstName">Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              className="joinUsFormInput joinUsFirstNameInput"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          </div>

          {/* Last Name */}
          <div className="joinUsFormGroup joinUsLastNameGroup">
            <label htmlFor="instaID">Insta ID</label>
            <input
              type="text"
              id="instaID"
              name="instaID"
              className="joinUsFormInput joinUsLastNameInput"
              value={formData.instaID}
              onChange={handleInputChange}
            />
          </div>

          {/* Email */}
          <div className="joinUsFormGroup joinUsEmailGroup">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="joinUsFormInput joinUsEmailInput"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>

          {/* Contact */}
          <div className="joinUsFormGroup joinUsEmailGroup">
            <label htmlFor="contact">Contact No.</label>
            <input
              type="tel"
              id="contact"
              name="contact"
              pattern="[0-9]{10}"
              maxLength="10"
              className="joinUsFormInput joinUsContactInput"
              value={formData.contact}
              onChange={handleInputChange}
              title="Contact number should be 10 digits"
            />
          </div>

          {/* Message */}
          <div className="joinUsFormGroup joinUsMessageGroup">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              className="joinUsFormTextarea joinUsMessageInput"
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
          </div>

          <p>
            Please attach your portfolio, photos, or any other relevant details to the email after pressing Submit
          </p>

          {/* Submit Button */}
          <div className="joinUsFormGroup joinUsSubmitButtonGroup">
            <button type="submit" className="joinUsSubmitBtn">
              Submit
            </button>
          </div>
        </form>
      </div>

      {/* Google Map */}
      <div className="joinUsMapContainer">
        <iframe
          className="googleMapLink"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1328.736547151641!2d72.8346453866384!3d19.13687246919355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b624970fffff%3A0x6ddb141f924f6b0b!2sMagnetic%20Production!5e0!3m2!1sen!2sin!4v1708589008422!5m2!1sen!2sin"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default JoinUsForm;
