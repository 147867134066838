import React, { useState } from 'react';
import '../App.css';
import ImageGrid from '../Components/ImageGrid/ImageGrid';
import './AboutUs.scss';
const AboutUs = () => {
  const images2 = [
    { src: '../../../../assets/images/ourTeam1.webp', alt: 'Image 1' },
    { src: '../../../../assets/images/ourTeam2.jpg', alt: 'Image 2' },
    { src: '../../../../assets/images/ourTeam3.jpg', alt: 'Image 3' },
    { src: '../../../../assets/images/ourTeam4.webp', alt: 'Image 4' },
  ];
  const title2 = 'Our Team';

  return (
    <div className="AboutPage">
      <div className="aboutUsDescriptionContainer">
        <h1 className="aboutUsDescription">
          Magnetic Talent Management is a leading agency specializing in
          modeling with a comprehensive focus on production and post-production
          services. Our agency represents a diverse range of models and offers
          end-to-end solutions to meet the needs of clients in various
          industries.
          <br/>
          <br />
          In our modeling division, we meticulously curate a roster of talented
          models, ensuring diversity, professionalism, and versatility. Whether
          it's fashion, commercial, editorial, or other genres, we provide
          access to models who embody the vision and requirements of each
          project.
          <br/>
          <br />
          Beyond talent representation, our agency excels in production
          management. We oversee every aspect of the production process,
          including location scouting, casting, wardrobe styling, hair, makeup,
          and more. Our goal is to bring our clients' visions to life seamlessly
          and efficiently.
          <br/>
          <br />
          In post-production, we employ cutting-edge editing techniques and
          software to refine and enhance the captured images. From retouching to
          final editing, we ensure consistency, quality, and a polished finish
          that aligns with the client's objectives and resonates with the
          intended audience.
          <br/>
          <br />
          With Magnetic Talent Management, clients can expect a seamless and
          integrated approach to modeling, production, and post-production, all
          under one roof. Our dedication to excellence, creativity, and client
          satisfaction sets us apart as a trusted partner in bringing visions to
          reality.
        </h1>
      </div>
      <ImageGrid title={title2} images={images2} />
    </div>
  );
};

export default AboutUs;
