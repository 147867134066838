import  "./footer.css";
import '../../App.css'
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer-main">
           <div className="footer-container">
            <div className="first-row">
                <div className="footer-logo">
                    <img src={"../../../magnetic-white.png"} alt="Company Logo" />
                    <p>Magnetic Talent Management</p>
                </div>
                <div className="footer-links">
                    <div className="links-container">
                        <ul>
                            <li><NavLink to="/talents">Talents</NavLink></li>
                            <li><NavLink to="/preproduction">Production</NavLink></li>
                            <li><NavLink to="/production">Post Production</NavLink></li>
                            <li><NavLink to="/aboutus">About Us</NavLink></li>
                        </ul>
                        
                    </div>
                </div>
                <div className="Second-row">
                  <NavLink to="/joinus">Join Us</NavLink>
                </div>
            </div>
           
            <div className="line"></div>
            <div className="third-row">
                <div className="footer-social">
                    <div className="social-icons">
                        <img className="icon-image" src={"../../assets/icons/instagram_icon.png"} />    
                        <img className="icon-image" src={"../../assets/icons/facebook_icon.png"} />    
                        <img className="icon-image" src={"../../assets/icons/youtube_icon.png"} />
                    </div>
                </div>
            </div>

            <div className="fourth-row">
                <p className="footer-copyright">© COPYRIGHT. ALL RIGHTS RESERVED</p>
            </div>
           </div> 
        </footer>
    );
};

export default Footer;

