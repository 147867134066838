import React from 'react';
import ModelsGalleryView from '../Components/Models Gallery View/modelsGalleryView';
import modelsData from '../models.json';

const Talents = ({ selectedCategory, selectedSubcategory, selectedSubSubcategory, selectedSubSubSubcategory }) => {
  const filteredModels = modelsData.filter(model => {
    // Match the model's category with the selected subcategory
    console.log(selectedCategory, selectedSubcategory, selectedSubSubcategory, selectedSubSubSubcategory, model.category, model.subCategory)

    const matchesCategoryWithSubcategory = selectedSubcategory ? model.category.toLowerCase() === selectedSubcategory.toLowerCase() : true;

    // Match the model's subcategory with the selected subsubcategory
    const matchesSubcategoryWithSubSubcategory = selectedSubSubcategory ? model.subCategory === selectedSubSubcategory : true;

    // Match the model's subsubcategory with the selected subsubsubcategory
    const matchesSubSubcategoryWithSubSubSubcategory = selectedSubSubSubcategory ? model.subSubCategory === selectedSubSubSubcategory : true;

    // A model is included if all the above matches are true
    return matchesCategoryWithSubcategory && matchesSubcategoryWithSubSubcategory && matchesSubSubcategoryWithSubSubSubcategory;

  });

  console.log(filteredModels)
  return (
    <div className="talentsPage">
      <ModelsGalleryView models={filteredModels} />
    </div>
  );
};

export default Talents;
