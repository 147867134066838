import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Profile.scss';
import ProfileImageScroll from '../ProfileImageScroll/ProfileImageScroll';

const Profile = () => {
  const [date, setDate] = useState('');
  const [product, setProduct] = useState('');
  const [fashionfilms, setFashionfilms] = useState('')
  const [usage, setUsage] = useState('');
  const [contact, setContact] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedPills, setSelectedPills] = useState([]);

  const location = useLocation();
  // get the old url from the location object
  const oldUrl = location.pathname;
  const { model } = location.state;

  const [formData, setFormData] = useState({
    date: '',
    product: '',
    quantity: 1,
    usage: [], // Initialize usage as an empty array
    fashionfilms: '',
    quantity2: 1,
    contact: '',
    name: '',
    email: '',
  });

  const togglePill = (pill) => {
    setFormData((prevFormData) => {
      const isPillSelected = prevFormData.usage.includes(pill);
      const newUsage = isPillSelected
        ? prevFormData.usage.filter((u) => u !== pill) // Remove the pill
        : [...prevFormData.usage, pill]; // Add the pill

      return { ...prevFormData, usage: newUsage };
    });
  };

  // Combine selected pills and manual input
  const usageValue = selectedPills.join(', ');

  // Function to capitalize the first letter of each stat for display
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  // State to hold the quantity value
  const [quantity, setQuantity] = useState(0);

  // Function to increment the quantity
  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  // Function to decrement the quantity, ensuring it doesn't go below 0
  const decrementQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 0 ? prevQuantity - 1 : 0));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUsageChange = (usageValue) => {
    setFormData((prevFormData) => {
      const usageArray = prevFormData.usage.includes(usageValue)
        ? prevFormData.usage.filter((u) => u !== usageValue)
        : [...prevFormData.usage, usageValue];

      return { ...prevFormData, usage: usageArray };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Prepare email body with the form data
    let emailBody = `Date: ${formData.date}, Product: ${
      formData.product
    }, Product Quantity: ${formData.quantity}, Fashion Films/Reels: ${formData.fashionfilms}, Quantity: ${formData.quantity2},  Usage: ${formData.usage.join(
      ', ',
    )}, Contact: ${formData.contact}, Name: ${formData.name}, Email: ${
      formData.email
    }`;
    // Construct mailto link
    let encodedSubject = encodeURIComponent(emailBody);
    const mailtoLink = `mailto:magneticinbox@gmail.com?subject=Book The Model: ${encodedSubject}`;
    window.location.href = mailtoLink;
  };

  return (
    <div id="profileTopContainer" className="profileContainerMain">
      <div className="profileContainer">
        <div className="profileNameStatsIconsContainer">
          <h1 className="profileName">{model.name}</h1>
          {model.stats && Object.keys(model.stats).length > 0 && (
            <div className="profileStatsContainer">
              {Object.entries(model.stats).map(([key, value], index) => (
                <div key={index} className="profileStatItem">
                  <span className="profileStatKey">
                    {capitalizeFirstLetter(key.replace(/([A-Z])/g, ' $1'))}:
                  </span>
                  <span className="profileStatValue"> {value}</span>
                </div>
              ))}
            </div>
          )}
          <div className="profileIconsContainer">
            {model.pdf && (
              <a
                href={model.pdf}
                target="_blank"
                rel="noopener noreferrer"
                className="profileBtn profilePDFBtn"
              >
                Download PDF
              </a>
            )}
            {/* <a className="profileBtn" href="https://wa.me/919137892740">
              <img
                className="BtnSVG"
                src="../../assets/icons/whatsapp_profile_icon.svg"
                alt="WhatsApp"
              />
            </a>
            <a className="profileBtn" href="mailto:magneticinbox@gmail.com">
              <img
                className="BtnSVG"
                src="../../assets/icons/mail_profile_icon.svg"
                alt="Email"
              />
            </a> */}
          </div>
        </div>
        <ProfileImageScroll images={model.images} category={model.category}/>
        <div className="profileFormContainerTitle">
          {model.category.toLowerCase() === 'male' ||
          model.category.toLowerCase() === 'female' ||
          model.category.toLowerCase() === 'kids' ||
          model.category.toLowerCase() === 'senior' ? (
            <>
              <h1 className="profileFormTitle">Book The Model</h1>
              <div className="profileFormContainer">
                <form className="profileForm" onSubmit={handleSubmit}>
                  <div className="formGroup dateGroup">
                    <label htmlFor="date">Shoot Date</label>
                    <input
                      type="date"
                      id="date"
                      name="date"
                      className="formInput dateInput"
                      value={formData.date}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="formGroup productGroup">
                    <label htmlFor="product">Products</label>
                    <input
                      type="text"
                      id="product"
                      name="product"
                      className="formInput productInput"
                      value={formData.product}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="formGroup quantityGroup">
                    <label htmlFor="quantity">Product Quantity</label>
                    <div className="quantityContainer">
                      <button
                        type="button"
                        className="quantityBtn decreaseBtn"
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            quantity: Math.max(1, prev.quantity - 1),
                          }))
                        }
                      >
                        -
                      </button>
                      <span className="quantityDisplay">
                        {formData.quantity}
                      </span>
                      <button
                        type="button"
                        className="quantityBtn increaseBtn"
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            quantity: prev.quantity + 1,
                          }))
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="formGroup productGroup">
                    <label htmlFor="fashionfilms">Fashion Films/Reels</label>
                    <input
                      type="text"
                      id="fashionfilms"
                      name="fashionfilms"
                      className="formInput productInput"
                      value={formData.fashionfilms}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formGroup quantityGroup">
                    <label htmlFor="quantity">Quantity</label>
                    <div className="quantityContainer">
                      <button
                        type="button"
                        className="quantityBtn decreaseBtn"
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            quantity2: Math.max(1, prev.quantity2 - 1),
                          }))
                        }
                      >
                        -
                      </button>
                      <span className="quantityDisplay">
                        {formData.quantity2}
                      </span>
                      <button
                        type="button"
                        className="quantityBtn increaseBtn"
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            quantity2: prev.quantity2 + 1,
                          }))
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="formGroup usageGroup">
                    <label htmlFor="usage">Usage</label>
                    <input
                      type="text"
                      id="usage"
                      name="usage"
                      className="formInput usageInput"
                      value={formData.usage.join(', ')} // Join selected usage pills with commas
                      onChange={handleInputChange}
                      readOnly // Optional: make it readOnly if you don't want users to type directly
                    />
                    <div className="labelPillsContainer">
                      {[
                        'Ecommerce',
                        'Social Media',
                        'Catalogue',
                        'All Print Media',
                        'All Outdoor Media',
                      ].map((usage, index) => (
                        <span
                          key={index}
                          className={`labelPill ${
                            formData.usage.includes(usage) ? 'selectedPill' : ''
                          }`}
                          onClick={() => togglePill(usage)}
                        >
                          {usage}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="formGroup contactGroup">
                    <label htmlFor="contact">Contact</label>
                    <input
                      type="tel"
                      id="contact"
                      name="contact"
                      className="formInput contactInput"
                      value={formData.contact}
                      onChange={handleInputChange}
                      pattern="[0-9]{10}" // Regex pattern allows exactly 10 digits only
                      maxLength="10" // Max length set to 10 digits
                      title="Contact number should be 10 digits"
                    />
                  </div>

                  <div className="formGroup nameGroup">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="formInput nameInput"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="formGroup emailGroup">
                    <label htmlFor="email">Email ID</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="formInput emailInput"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="formGroup submitButtonGroup">
                    <button type="submit" className="submitBtn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
