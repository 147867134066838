import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { transformCategories } from '../../util/transformCategories';
import './Navbar.css';
import '../../App.css';

const Navbar = ({ data, onCategorySelect }) => {
  const [menuStructure, setMenuStructure] = useState([]);
  const [selectedPath, setSelectedPath] = useState([]);
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation hook to get the current path
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const transformedData = transformCategories(data);
    setMenuStructure(transformedData);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1095);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [data]);

  const handleSelect = (path, hasChildren, isPrimary) => {
    if (isPrimary) {
      const newPath = path.length > 0 ? '/' + path[0].toLowerCase() : '/';
      navigate(newPath);

      if (hasChildren) {
        if (selectedPath.join('>') === path.join('>')) {
          setSelectedPath([]);
        } else {
          setSelectedPath(path);
        }
      } else {
        setSelectedPath(path);
      }
    } else {
      setSelectedPath(path);
    }

    const [
      selectedCategory,
      selectedSubcategory,
      selectedSubSubcategory,
      selectedSubSubSubcategory,
    ] = path;
    onCategorySelect(
      selectedCategory,
      selectedSubcategory,
      selectedSubSubcategory,
      selectedSubSubSubcategory,
    );
  };

  const handleMobileMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const hideDesktopMenu = ['/aboutus', '/joinus'].includes(location.pathname); 

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1095 && hideDesktopMenu) {
        // Add your logic here to hide the desktopMenuContainer
        document.body.classList.add('hide-desktop-menu');
      } else {
        document.body.classList.remove('hide-desktop-menu');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it once immediately

    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.classList.remove('hide-desktop-menu'); // Clean up the class when the component unmounts
    };
  }, [hideDesktopMenu]);

  const renderMenu = (menu, path = []) => {
    return menu.map((item, index) => {
      const currentPath = [...path, item.name];
      const isPathSelected = selectedPath
        .join('>')
        .startsWith(currentPath.join('>'));
      const hasChildren = item.children && item.children.length > 0;
      const linkPath =
        '/' + currentPath.slice(0, 1).join('/').toLowerCase().replace(' ', '');
      const uniqueId = `nav-link-${item.name
        .toLowerCase()
        .replace(/\s+/g, '-')}`;
      const uniqueIdDiv = `div-link-${item.name
        .toLowerCase()
        .replace(/\s+/g, '-')}`;
      return (
        <div
          key={index}
          id={uniqueIdDiv}
          className={`menuItem ${hasChildren ? 'has-children' : ''} ${
            isPathSelected ? 'selected' : ''
          }`}
        >
          <NavLink
            to={linkPath}
            id={uniqueId}
            className={`navLink ${isPathSelected ? 'selectedLink' : ''}`}
            onClick={(e) => {
              if (hasChildren) {
                e.preventDefault();
              }
              handleSelect(currentPath, hasChildren, path.length === 0);
            }}
          >
            {item.name === 'Preproduction' ? 'Production' : item.name === 'Production' ? 'Post Production' : item.name}
          </NavLink>
          {isPathSelected && hasChildren && (
            <div className="childrenContainer">
              {renderMenu(item.children, currentPath)}
            </div>
          )}
        </div>
      );
    });
  };

  const renderMobileMenuLinks = () => {
    return (
      <>
        <NavLink
          to="/aboutus"
          onClick={handleMobileMenuToggle}
          className="mobileNavLink navLink"
        >
          About Us
        </NavLink>
        <NavLink
          to="/joinus"
          onClick={handleMobileMenuToggle}
          className="mobileNavLink navLink"
        >
          Join Us
        </NavLink>
      </>
    );
  };

  return (
    <div className="navbar">
      <div className="logoContainer">
        <NavLink to="/" onClick={() => setSelectedPath([])}>
          <img
            src="../../../Logo.png"
            alt="logo"
            className="headerLogoImage"
          />
        </NavLink>
      </div>
      <div className={`desktopMenuContainer ${hideDesktopMenu && isMobile ? 'hidden' : ''}`}>{renderMenu(menuStructure)}</div>{' '}
      {/* Desktop menu container */}
      {!isMenuOpen && (
        <div className="hamburger" onClick={handleMobileMenuToggle}>
          <img src="/black_hamburger.svg" alt="menu" />
        </div>
      )}
      {isMobile && isMenuOpen && (
        <div className="mobileMenu">
          <div className="crossButton" onClick={handleMobileMenuToggle}>
            X
          </div>
          {renderMobileMenuLinks()} {/* Mobile menu links */}
        </div>
      )}
    </div>
  );
};

export default Navbar;
