import React, { useState } from 'react';
import styles from './Admin.module.css';

const categories = {
  preproduction: ['CONCEPT', 'STUDIO', 'PHOTOGRAPHY', 'FASHION FILMS', 'REELS'],
  production: [
    'IMAGES RETOUCHING',
    'VIDEO EDITING',
    'DI',
    'VFX',
    'MOTION GRAPHICS',
  ],
};

const Admin = () => {
  //TODO: Add hair length, hair color,
  const [modelSubCategory, setSubCategory] = useState('');
  const [entryType, setEntryType] = useState('');
  const [category, setCategory] = useState('');
  const [apiKey, setApiKey] = useState(''); // State for storing the API key
  const [modelData, setModelData] = useState({
    name: '',
    height: '',
    bust: '',
    waist: '',
    hips: '',
    shoeSize: '',
    eyeColor: '',
    hairLength: '', // Added hair length
    hairColor: '', // Added hair color
  });

  const [images, setImages] = useState([]);
  const [pdf, setPdf] = useState(null);
  const [brandName, setBrandName] = useState('');

  const handleInputChange = ({ target: { name, value, files } }) => {
    if (name === 'images') {
      const newFiles = Array.from(files);
      let updatedFiles = [...images, ...newFiles.slice(0, 10 - images.length)];

      // Ensure first file is an image if any image is present
      if (
        updatedFiles.find((file) => file.type.startsWith('image/')) &&
        !updatedFiles[0].type.startsWith('image/')
      ) {
        const firstImageIndex = updatedFiles.findIndex((file) =>
          file.type.startsWith('image/'),
        );
        const firstImage = updatedFiles[firstImageIndex];
        updatedFiles.splice(firstImageIndex, 1); // Remove the first image from its current position
        updatedFiles.unshift(firstImage); // Add the image to the start
      }
      setImages(updatedFiles);
    } else if (name === 'pdf') {
      setPdf(files[0]);
    } else if (name === 'brandName') setBrandName(value);
    else if (name === 'apiKey')
      setApiKey(value); // Handling API key input change
    else {
      setModelData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (images.length === 0 || !images[0].type.startsWith('image/')) {
      alert('Please upload at least one image as the first file.');
      return;
    }

    const formData = new FormData();
    Object.entries({
      ...modelData,
      brandName,
      entryType,
      category,
      apiKey,
      modelSubCategory,
    }).forEach(([key, value]) => {
      if (value) {
        if (key === 'modelSubCategory') {
          key = 'modelCategory';
        }
        formData.append(key, value);
      }
    });
    images.forEach((image) => formData.append('images', image));
    if (pdf) formData.append('pdf', pdf);

    try {
      const response = await fetch(
        // customer-apis project on github is called admin.wlbs.dev
        'https://admin.wlbs.dev/api/magnetic-productions/submit',
        {
          method: 'POST',
          body: formData,
          headers: {
            Accept: 'application/json',
            // Do not set 'Content-Type' header when sending FormData
            // Fetch will automatically set it to 'multipart/form-data'
            apiKey: apiKey, // This header might not work as expected, consider handling apiKey differently
          },
        },
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Submission successful', result);
      alert('Submission successful');
    } catch (error) {
      console.error('Submission failed', error);
      alert('Submission failed');
    }
  };

  const renderModelSubCategoriesOptions = () => {
    const options = {
      MALE: [],
      FEMALE: [],
      SENIOR: ['MALE', 'FEMALE'],
      KIDS: [{ GIRLS: ['BELOW 10', '10-20'] }, { BOYS: ['BELOW 10', '10-20'] }],
    };

    const optionElements = [];

    Object.entries(options).forEach(([mainCategory, subCategories]) => {
      if (subCategories.length === 0) {
        // If there are no subcategories, add the main category directly
        optionElements.push(mainCategory);
      } else {
        subCategories.forEach((subCategory) => {
          if (typeof subCategory === 'string') {
            // Directly handle string subcategories
            optionElements.push(`${mainCategory} - ${subCategory}`);
          } else if (typeof subCategory === 'object') {
            // Handle object subcategories (for KIDS)
            Object.entries(subCategory).forEach(
              ([subCategoryName, subSubCategories]) => {
                subSubCategories.forEach((subSubCategory) => {
                  optionElements.push(
                    `${mainCategory} - ${subCategoryName} - ${subSubCategory}`,
                  );
                });
              },
            );
          }
        });
      }
    });

    return optionElements.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ));
  };

  const renderModelInputs = () =>
    Object.keys(modelData).map((key) => {
      let placeholder =
        key.charAt(0).toUpperCase() +
        key
          .slice(1)
          .replace(/([A-Z])/g, ' $1')
          .trim(); // Improved placeholder text formatting
      // Custom placeholders for specific fields
      switch (key) {
        case 'height':
          placeholder = 'Height (in cm)';
          break;
        case 'shoeSize':
          placeholder = 'Shoe Size (in US)';
          break;
        case 'eyeColor':
          placeholder = 'Eye Color';
          break;
        case 'hairLength':
          placeholder = 'Hair Length (in inches)';
          break;
        case 'hairColor':
          placeholder = 'Hair Color';
          break;
        case 'bust':
          placeholder = 'Bust (in inches)';
          break;
        case 'waist':
          placeholder = 'Waist (in inches)';
          break;
        case 'hips':
          placeholder = 'Hips (in inches)';
          break;
        default:
          break;
      }
      if (key === 'shoeSize') {
        return (
          <select
            key={key}
            name={key}
            value={modelData[key]}
            onChange={handleInputChange}
            className={styles.inputField}
          >
            <option value="">Select Shoe Size</option>
            <option value="2">2 US</option>
            <option value="3">3 US</option>
            <option value="4">4 US</option>
            <option value="5">5 US</option>
            <option value="6">6 US</option>
            <option value="7">7 US</option>
            <option value="8">8 US</option>
            <option value="9">9 US</option>
            <option value="10">10 US</option>
            <option value="11">11 US</option>
            <option value="12">12 US</option>
            <option value="13">13 US</option>
          </select>
        );
      }

      if (key === 'hairColor') {
        return (
          <select
            key={key}
            name={key}
            value={modelData[key]}
            onChange={handleInputChange}
            className={styles.inputField}
          >
            <option value="">Select Hair Color</option>
            <option value="Black">Black Hair</option>
            <option value="Brown">Brown Hair</option>
            <option value="Blonde">Blonde Hair</option>
            <option value="Red">Red Hair</option>
            <option value="Other">Other Hair</option>
          </select>
        );
      }

      if (key === 'eyeColor') {
        return (
          <select
            key={key}
            name={key}
            value={modelData[key]}
            onChange={handleInputChange}
            className={styles.inputField}
          >
            <option value="">Select Eye Color</option>
            <option value="Black">Black Eyes</option>
            <option value="Brown">Brown Eyes</option>
            <option value="Blue">Blue Eyes</option>
            <option value="Green">Green Eyes</option>
            <option value="Hazel">Hazel Eyes</option>
            <option value="Other">Other Eyes</option>
          </select>
        );
      }

      return (
        <input
          key={key}
          type="text"
          name={key}
          value={modelData[key]}
          onChange={handleInputChange}
          placeholder={placeholder}
          className={styles.inputField}
        />
      );
    });

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <h2 style={{ padding: '20px' }}>Admin Page</h2>
        <input
          type="password"
          name="apiKey"
          value={apiKey}
          onChange={handleInputChange}
          placeholder="Password"
          className={styles.inputField}
        />
        {(entryType === 'preproduction' || entryType === 'production') && (
          <input
            type="text"
            name="brandName"
            value={brandName}
            onChange={handleInputChange}
            placeholder="Brand Name"
            className={styles.inputField}
          />
        )}
        <select
          name="entryType"
          value={entryType}
          onChange={(e) => setEntryType(e.target.value)}
          className={styles.inputField}
        >
          <option value="">Select Entry Type</option>
          <option value="model">Model</option>
          <option value="preproduction">Pre Production</option>
          <option value="production">Production</option>
        </select>
        {['preproduction', 'production'].includes(entryType) && (
          <select
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className={styles.inputField}
          >
            <option value="">Select {entryType} Category</option>
            {categories[entryType].map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        )}
        {entryType === 'model' && (
          <>
            <select
              name="modelCategory"
              value={modelSubCategory}
              onChange={(e) => setSubCategory(e.target.value)}
              className={styles.inputField}
            >
              <option value="">Select Model Category</option>
              {renderModelSubCategoriesOptions()}
            </select>
          </>
        )}
        {entryType === 'model' && renderModelInputs()}
        {entryType === 'model' && (
          <label className={styles.inputField}>
            Upload PDF
            <br />
            <input
              type="file"
              name="pdf"
              onChange={handleInputChange}
              accept=".pdf"
              className={styles.fileInput}
            />
          </label>
        )}
        <label className={styles.inputField}>
          Upload Images
          <br />
          <input
            type="file"
            name="images"
            onChange={handleInputChange}
            accept="image/*"
            multiple
            className={styles.fileInput}
          />
        </label>
        <button type="submit" className={styles.submitButton}>
          Submit
        </button>
      </form>
    </>
  );
};

export default Admin;
