import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import './HomePageBanner.scss'
import '../App.css'

const HomePageBanner = ({ id, setid, insta }) => {
  return (
    <>
        <div className="homePageBanner">
          <img className="homepageBannerImage" src="../../../../assets/images/homepagebanner.jpeg" alt="Image 1" />
    </div>
    </>
  );
};

export { HomePageBanner };
