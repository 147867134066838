import React from 'react';
import "../App.css";
import { HomePageBanner } from "../HomePageBanner/HomePageBanner";
import ImageGrid from "../Components/ImageGrid/ImageGrid";

const Homepage = () => {
  // Define your title and images array here
  const title = "Featured Models";
  const images = [
    { src: "../../../../assets/images/feature1.webp", alt: "Image 1",href:"http://localhost:3000/profile/Aashir" },
    { src: "../../../../assets/images/feature2.webp", alt: "Image 2", href:"http://localhost:3000/profile/Aashir" },
    { src: "../../../../assets/images/feature3.webp", alt: "Image 3", href:"http://localhost:3000/profile/Aashir" },
    { src: "../../../../assets/images/feature4.webp", alt: "Image 4", href:"http://localhost:3000/profile/Aashir" }
  ];

  return (
    <div className="landingPage">
      <HomePageBanner />
      <ImageGrid title={title} images={images}   />
    </div>
  );
}

export default Homepage;
