export const transformCategories = (data) => {
  if (!data || typeof data !== 'object') {
    // Return an empty array or some default structure if data is not an object
    return [];
  }

  // Include top-level categories (e.g., preproduction, production, models) as main headings
  return Object.entries(data).map(([key, value]) => {
    if (!value || !value.categories) {
      // If value or value.categories is not defined, skip this entry
      return null;
    }

    return {
      name: key, // Use the key (e.g., preproduction) as the category name
      children: value.categories.map(category => {
        if (!category || !category.subCategories) {
          // If category or category.subCategories is not defined, skip this entry
          return null;
        }

        return {
          name: category.categoryName,
          children: category.subCategories.map(subCategory => {
            if (!subCategory || !subCategory.subSubCategories) {
              // If subCategory or subCategory.subSubCategories is not defined, skip this entry
              return null;
            }

            return {
              name: subCategory.subCategoriesName,
              children: subCategory.subSubCategories.map(subSubCategory => ({
                name: subSubCategory.subSubCategoriesName,
                children: [], // Assuming no further nesting
              })),
            };
          }).filter(Boolean), // Filter out null entries
        };
      }).filter(Boolean), // Filter out null entries
    };
  }).filter(Boolean); // Filter out null entries
};
