import React from 'react';
import './ImageGrid.scss';
import '../../App.css';
import { Link } from 'react-router-dom';

const ImageGrid = ({ title, images }) => {
  const model = {
    "images": [
      "https://wlbs-crm.s3.ap-south-1.amazonaws.com/magnetic-productions/model/aashir/images/14.jpg",
      "https://wlbs-crm.s3.ap-south-1.amazonaws.com/magnetic-productions/model/aashir/images/12.jpg",
      "https://wlbs-crm.s3.ap-south-1.amazonaws.com/magnetic-productions/model/aashir/images/1.jpg",
      "https://wlbs-crm.s3.ap-south-1.amazonaws.com/magnetic-productions/model/aashir/images/2.jpg",
      "https://wlbs-crm.s3.ap-south-1.amazonaws.com/magnetic-productions/model/aashir/images/3.jpg",
      "https://wlbs-crm.s3.ap-south-1.amazonaws.com/magnetic-productions/model/aashir/images/4.jpg",
      "https://wlbs-crm.s3.ap-south-1.amazonaws.com/magnetic-productions/model/aashir/images/6.jpg"
    ],
    "pdf": "https://wlbs-crm.s3.ap-south-1.amazonaws.com/magnetic-productions/model/aashir/pdfs/profile.pdf",
    "entryType": "model",
    "name": "Aashir",
    "stats": {
      "height": "189",
      "bust": "32",
      "waist": "32",
      "hips": "32",
      "shoeSize": "12",
      "eyeColor": "Brown",
      "hairLength": "5",
      "hairColor": "Black"
    },
    "category": "Senior",
    "subCategory": "Male"
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const isOurTeamTitle = title.toLowerCase() === 'our team';

  return (
    <div className='container'>
      <div className='title-images'>
        <h2 className='title'>{title}</h2>
        <div className="image-grid-container">
          {images.map((image, index) => {
            const imageElement = <img className="images-grid-item" key={index} src={image.src} alt={image.alt} />;
            return isOurTeamTitle ? (
              <div key={index} className="image-grid-item">{imageElement}</div>
            ) : (
              <Link key={index} className="image-grid-item" onClick={scrollToTop} to={`/profile/${model.name}`} state={{ model }}>{imageElement}</Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ImageGrid;
