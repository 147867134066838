import React, { useEffect, useMemo } from 'react';
import lozad from 'lozad'; // Import lozad
import './ProfileImageScroll.scss';

const ProfileImageScroll = ({ images, category }) => {
  // Function to check if the image URL ends with .mp4
  const isVideo = (url) => {
    return url.match(/\.(mp4)$/) !== null;
  };

  useEffect(() => {
    // Initialize lozad observer for elements with the 'lozad' class
    const observer = lozad('.lozad', {
      loaded: (el) => {
        // Optional: callback when an element is loaded
        console.log(`${el.tagName} element is loaded.`);
      }
    });
    observer.observe(); // Start observing
  }, []);

  // Sort images so that videos come first for specific categories
  const sortedImages = useMemo(() => {
    if (['fashion films', 'reels'].includes(category.toLowerCase())) {
      return [...images].sort((a, b) => {
        const isAVideo = isVideo(a);
        const isBVideo = isVideo(b);

        if (isAVideo && !isBVideo) return -1;
        if (!isAVideo && isBVideo) return 1;
        return 0;
      });
    }

    return images;
  }, [images, category]);

  return (
    <div className="profileImageScrollContainer">
      <div className="profileImages">
        {sortedImages.map((image, index) => {
          // Check if the current item is a video
          if (isVideo(image)) {
            return (
              <video key={index} className="profileImageItem lozad" muted loop controls data-src={image} type="video/mp4">
                Your browser does not support the video tag.
              </video>
            );
          } else {
            // Use data-src instead of src and add lozad class for lazy loading
            return (
              <img key={index} data-src={image} alt={`Model ${index + 1}`} className="profileImageItem lozad" />
            );
          }
        })}
      </div>
    </div>
  );
};

export default ProfileImageScroll;
