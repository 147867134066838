import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header/header";
import Footer from "./Components/Footer/footer";
import Homepage from "./Pages/Homepage";
import Talents from "./Pages/Talents";
import Admin from "./Pages/Admin";
import Profile from "./Components/Profile/Profile";
import PreProductions from "./Pages/PreProductions";
import PostProductions from "./Pages/PostProduction";
import AboutUs from "./Pages/AboutUs";
import JoinUsForm from "./Pages/JoinUs";
import Navbar from "./Components/Navbar/Navbar";
import data from "./categories.json"; // Ensure the path to your categories.json is correct
import json from "./data.json"; // Assuming this is additional data you might need


function App() {
  const [selections, setSelections] = useState({
    selectedCategory: null,
    selectedSubcategory: null,
    selectedSubSubcategory: null,
    selectedSubSubSubcategory: null // Added this new state
  });

  const handleCategorySelect = (selectedCategory, selectedSubcategory, selectedSubSubcategory, selectedSubSubSubcategory) => { // Handling the new level
    setSelections({ selectedCategory, selectedSubcategory, selectedSubSubcategory, selectedSubSubSubcategory });
  };


  const [id, setid] = useState("");
  const [insta, setInsta] = useState([]);

  useEffect(() => {
    console.log("App component mounted.");
    
    // Define your async function inside the useEffect for fetching Instagram media
    const getMedia = async () => {
      console.log("Fetching Instagram media...");
      // Your Instagram data fetching logic here...
      // Example:
      // const response = await fetch("Your Instagram API Endpoint");
      // const data = await response.json();
      // setInsta(data);
      // console.log("Instagram media fetched:", data);
    };

    getMedia();
  }, []);



  return (
    <Router>
      <div className="App">
        {/* Header component, uncomment if needed */}
        {/* <Header externalLinks={json.header.externalLinks} data={json.header} products={json.products} id={id} setid={setid} /> */}

        <Navbar data={data} onCategorySelect={handleCategorySelect} />

        <Routes>
          <Route path="/" element={<Homepage id={id} setid={setid} insta={insta} />} />
          <Route path="/talents" element={<Talents {...selections} />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/profile/:modelName" element={<Profile />} />
          <Route path="/preproduction" element={<PreProductions {...selections} />} />
          <Route path="/production" element={<PostProductions {...selections} />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/joinus" element={<JoinUsForm />} />
          {/* Add more routes as needed */}
        </Routes>

        <Footer data={json.products} />
      </div>
    </Router>
  );
}

export default App;
