import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import lozad from 'lozad'; // Import lozad
import './modelsGalleryView.scss';

const ModelsGalleryView = ({ models }) => {

  const isVideo = (url) => {
    return url.match(/\.(mp4)$/) !== null;
  };

  useEffect(() => {
    // Initialize lozad observer for elements with the 'lozad' class
    const observer = lozad('.lozad', {
      loaded: (el) => {
        // Optional: callback when an element is loaded
        console.log(`${el.tagName} element is loaded.`);
      }
    });
    observer.observe(); // Start observing
  }, [models]); // Re-run the effect when models change

  return (
    <div className="galleryContainer">
      {models.length > 0 ? (
        models.map((model, index) => (
          isVideo(model.images[0]) ? (
            <video key={index} className="galleryItemImage lozad" muted loop data-src={model.images[0]} type="video/mp4">
              <source type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div key={index} className="galleryItem">
              <Link to={`/profile/${model.name}`} state={{ model }}>
                {/* Use data-src instead of src and add lozad class for lazy loading */}
                <img data-src={model.images[0]} alt={model.name} className="galleryItemImage lozad" />
              </Link>
            </div>
          )
        ))
      ) : (
        <div>No models found for the selected criteria.</div>
      )}
    </div>
  );
};

export default ModelsGalleryView;
